.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Nunito-regular"; /*Can be any text*/
  src: local("NunitoRegular"),
    url("./assets/fonts/Nunito/static/Nunito-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-semibold"; /*Can be any text*/
  src: local("NunitoRegular"),
    url("./assets/fonts/Nunito/static/Nunito-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-medium"; /*Can be any text*/
  src: local("Nunito-medium"),
    url("./assets/fonts/Nunito/static/Nunito-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-bold"; /*Can be any text*/
  src: local("Nunito-bold"),
    url("./assets/fonts/Nunito/static/Nunito-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-italic"; /*Can be any text*/
  src: local("Nunito-italic"),
    url("./assets/fonts/Nunito/static/Nunito-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-light"; /*Can be any text*/
  src: local("Nunito-light"),
    url("./assets/fonts/Nunito/static/Nunito-Light.ttf") format("truetype");
}
.wave-body {
  /* color: white;
  font-family: Arial, sans-serif;

  height: auto;
  margin: 0;
  padding: 0;  */
  /* display: flex; */
  justify-content: center;
  align-items: flex-end;
  width: auto;
  height: auto;
  /* min-width: 500px; */
  /* height: 100vh; */
  /* width: 200vh; */
  /* max-width: 90%; prevent from becoming too wide */
  /* background-color: black; */
  /* padding: 20px; */
  /* border-radius: 10px; */
  color: white; /* enforce white color for text */
  font-family: "Courier New", Courier, monospace; /* change the font */
  font-weight: bold;
  font-size: 18px; /* adjust the font size */
  /* box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.5); */
}
.wave-bckgnd {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* justify-content: flex-end;
  align-items: flex-end; */
  /* background-color: red;  */
  /* #8080803f slightly gray */
  /* color: black; */
  /* font-family: Arial, sans-serif; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: auto;
  width: auto;
  /* background: linear-gradient(180deg, #0077ff 0%, #00f7ff 100%); */
  /* animation: wave-animation 4s linear infinite; */
}

.video-container.rounded {
  border-radius: 20px; /* Adjust the value to control the degree of rounding */
  border-color: black;
}

.logo-header {
  position: "relative";
  width: "100%";
  height: "10%";
}

.call-style {
  display: flex;
  justify-content: space-evenly;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.logo-img {
  max-width: 20%;
  min-width: 100px;
  /* Set a percentage or pixel value that suits your layout */
  height: auto; /* Maintain the aspect ratio */
  /* margin: 0.5% 10%; */
  /* padding-left: 10%; */
}
